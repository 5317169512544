.section-sort {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
  column-gap: 2rem;
}
 
@media (max-width: 576px) {
    .section-sort{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 0.75rem;
    
    }
    .btn-container {
      width: 50px;
    }

    label {
      display: inline-block;
      margin-right: 0.5rem;
    }
}
  
@media (min-width: 768px) {
    .section-sort{
        column-gap: 2rem;
    }
    
}
  
p {
    text-transform: capitalize;
    margin-bottom: 0;
}

 
.btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    margin-left: 20px;
}

.btn-container .active {
    background: rgb(100 21 255 / var(--tw-bg-opacity));;
    color: white;
    border-radius: 4px;
  }

.btn-container button {
    background: transparent;
    border: 1px solid var(--clr-black);
    color: var(--clr-black);
    width: 2rem;
    border-radius: var(--radius);
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btn-container button svg {
    font-size: 1.5rem;
}

.sort-input {
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0.25rem 0.5rem;
  
}
  
label {
    font-size: 1rem;
    text-transform: capitalize;
}